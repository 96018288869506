
function Portfolio(){
    return <>

<section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>ERPBUS</h2>
          <p>Conheça o nosso software</p>
        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <img src="assets/img/portfolio/app1.jpg" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <div className="portfolio-links">
                  <a href="assets/img/portfolio/app1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img src="assets/img/portfolio/web1.jpg" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <div className="portfolio-links">
                  <a href="assets/img/portfolio/web1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 3"><i className="bx bx-plus"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <img src="assets/img/portfolio/app2.jpg" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <div className="portfolio-links">
                  <a href="assets/img/portfolio/app2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 2"><i className="bx bx-plus"></i></a>
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img src="assets/img/portfolio/web2.jpg" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <div className="portfolio-links">
                  <a href="assets/img/portfolio/web2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 2"><i className="bx bx-plus"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <img src="assets/img/portfolio/app3.jpg" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <div className="portfolio-links">
                  <a href="assets/img/portfolio/app3.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 3"><i className="bx bx-plus"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img src="assets/img/portfolio/web3.jpg" className="img-fluid" alt="" />
              <div className="portfolio-info">
                <div className="portfolio-links">
                  <a href="assets/img/portfolio/web3.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 3"><i className="bx bx-plus"></i></a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>

    </>
}


export default Portfolio;