
function NavBar(){
  return   <header id="header" className="fixed-top header-inner-pages">
  <div className="container d-flex align-items-center justify-content-lg-between">

    <h1 className="logo me-auto me-lg-0"><a href="index.html">ERP<span>BUS</span></a></h1>

    <nav id="navbar" className="navbar order-last order-lg-0">
      <ul>
        <li><a className="nav-link scrollto" href="/">Home</a></li>
        <li><a className="nav-link scrollto" href="/#about">Contabilidade</a></li>
        <li><a className="nav-link scrollto" href="/#services">Serviços</a></li>
        <li><a className="nav-link scrollto " href="/#portfolio">Erpbus</a></li>
        <li><a className="nav-link scrollto" href="/#planos">Planos</a></li> 
        <li><a className="nav-link scrollto" href="/#contact">Contato</a></li>
      </ul>
      <i className="bi bi-list mobile-nav-toggle"></i>
    </nav>

    <a href="http://www.erpbus.app.br:90" className="get-started-btn scrollto">Login</a>


  </div>
</header>


}

export default NavBar;