
function Contabilidade(){
  return <>
  <section id="about" className="about">
  <div className="container" data-aos="fade-up">

    <br />

    <div className="row">
      <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
        <img src="assets/img/about.jpg" className="img-fluid" alt="" />
      </div>
      <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">

        <h3>Contabilidade Integrada</h3>
        <p className="fst-italic">
          O outsourcing (mão-de-obra terceirizada) é uma das grandes tendências da administração moderna. Objetivando uma maior eficiência e redução de custos, as empresas têm delegado a terceiros áreas que não fazem parte do seu core business.
        </p>
        <ul>
          <li><i className="ri-check-double-line"></i> Constituição de empresas.</li>
          <li><i className="ri-check-double-line"></i> Escrituração contábil.</li>
          <li><i className="ri-check-double-line"></i> Recursos humano.</li>
          <li><i className="ri-check-double-line"></i> Serviços contábeis.</li>
          <li><i className="ri-check-double-line"></i> Tributário empresarial.</li>
          <li><i className="ri-check-double-line"></i> Assessoria juridica.</li>
          <li><i className="ri-check-double-line"></i> Emissão de Certificados Digitais eCnpj e eCpf.</li>
        </ul>
        <p>
          Nossa missão é somar sempre!!!
        </p>
      </div>
    </div>

  </div>
</section>
</>
}

export default Contabilidade;