import React from 'react';
import ReactDOM from 'react-dom/client';
import Rotas from './rotas';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<>

<Rotas />

</>

);