

function Banner(){
    return <section id="hero" className="d-flex align-items-center justify-content-center">
    <div className="container" data-aos="fade-up">

      <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
        <div className="col-xl-6 col-lg-8">
          <h1><span>Contabilidade e Tecnologia</span></h1>
          <h2>Software para Gestão do Transporte Rodoviário de Fretamento, tudo integrado em nossa Contabilidade ágil e moderna</h2>
        </div>
      </div>

      <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
        <div className="col-xl-2 col-md-4">
          <div className="icon-box">
            <i className="ri-bar-chart-box-line"></i>
            <h3><a href="#about">Contabilidade e RH</a></h3>
          </div>
        </div>
        <div className="col-xl-2 col-md-4">
          <div className="icon-box">
            <i className="ri-phone-fill"></i>
            <h3><a href="#planosparceiro">Certificado Digital eCNPJ e eCPF</a></h3>
          </div>
        </div>
        <div className="col-xl-2 col-md-4">
          <div className="icon-box">
            <i className="ri-bus-wifi-fill"></i>
            <h3><a href="http://www.erpbus.app.br:90">Sistema Gestão ErpBus</a></h3>
          </div>
        </div>
        <div className="col-xl-2 col-md-4">
          <div className="icon-box">
            <i className="ri-user-line"></i>
            <h3><a href="http://www.erpbus.app.br:93">Portal Agente Turistico</a></h3>
          </div>
        </div>
      </div>
      <br/>
    </div>
  </section>

}

export default Banner;