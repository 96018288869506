

function Servicos(){
    return <>
    <section id="services" className="services">
    <div className="container" data-aos="fade-up">

      <br />
      <div className="section-title">
        <h2>Serviços</h2>
        <p>Conheça nossos Serviços</p>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box">
            <div className="icon"><i className="bx bxl-dribbble"></i></div>
            <h4>Fiscal</h4>
            <p>Deixe sua contabilidade por nossa conta, realize suas viagens e no fechamento do mês a sua guia de pagamento referente aos impostos devidos estará disponível para pagamento no seu contas a pagar, tudo automatizado sem necessidade de envios de xmls.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box">
            <div className="icon"><i className="bx bx-user"></i></div>
            <h4>RH</h4>
            <p>Deixe a gestão de seus funcionários por conta da nossa contabilidade, e coloque suas energias no seu negócio.</p>
            <p>A folha de pagamento não terá custos até <b>03</b> colaboradores, caso tenha excedente será cobrado uma taxa de <b>R$ 20,00</b> por colaborador.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box">
            <div className="icon"><i className="bx bx-file"></i></div>
            <h4>Serviços Adicionais</h4>
            <p>Certificado eCnpj <b>R$ 100,00</b></p>
            <p>Certificado eCpf <b>R$ 100,00</b></p>
            <p>Abertura empresa <b>R$ 250,00</b> mais taxas Junta Comercial e Alvará</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box">
            <div className="icon"><i className="bx bx-bus"></i></div>
            <h4>App - Fretamento</h4>
            <p>Gestão de clientes</p>
            <p>Gestão de frota</p>
            <p>Gestão de contratos de fretamento</p>              
            <p>Gestão financeira dos contratos integrado com APP GUIA</p>
            <p>Lista de passageiros</p>              
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box">
            <div className="icon"><i className="bx bx-bus"></i></div>
            <h4>App - Excursões</h4>
            <p>Gestão dos passageiros</p>
            <p>Gestão das agendas das excursões</p>              
            <p>Agendamento passagens com escolha de poltronas</p>              
            <p>Lista de passageiros</p>
            <p>Gestão de encomendas</p>
            <p>Controle das bagagens no bagageiro do veículo</p>
            <p>Etiqueta eletrônica</p>
          </div>
        </div>


        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
          <div className="icon-box">
            <div className="icon"><i className="bx bx-bus"></i></div>
            <h4>App - Fiscal</h4>
            <p>Emissão de CTeOs</p>
            <p>Gestão dos documentos emitidos</p>
            <p>Envio dos arquivos fiscais (xml) caso não opte em utilizar nossa contabilidade</p>
            <p>Financeiro - Contas a pagar, Contas a Receber, Fluxo de Caixa...</p>
          </div>
        </div>


        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
          <div className="icon-box">
            <div className="icon"><i className="bx bx-bus"></i></div>
            <h4>App - Guia Fretamento</h4>
            <p>Aplicativo para os Guias acompanhar o status do contrato </p>
            <p>Controle dos pagamentos e a vinculação dos passageiros para a lista de passageiros</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box">
            <div className="icon"><i className="bx bx-bus"></i></div>
            <h4>Mobile - Motorista e Guia</h4>
            <p>Controle dos passageiros embarcados, confirmação de chegada, pagamento...</p>
            <p>Controle das bagagens no bagageiro do veículo</p>
            <p>Etiqueta eletrônica</p>
            <p>Desembarque</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
          <div className="icon-box">
            <div className="icon"><i className="bx bx-bus"></i></div>
            <h4>App - Novidades</h4>
            <p>Monitoramento - Checklist veículo e Monitriip</p>
            <p>Monitoramento - Rotas para embarque e entregas encomendas</p>
            <p>Passageiro - Embarque, Encomendas, Passagens e Pagamento</p>
            <p>Passageiro - Atendimento SAC 0800</p>
            <p>Whats - Agendamento de passagens</p>
            <p><b>Aguarde.. Em Breve!!!</b></p>
          </div>
        </div>

      </div>

    </div>
  </section>


</>
}

export default Servicos;