
function Footer(){
    return <>
    
    <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">

        <div className="col-lg-3 col-md-6">
            <div className="footer-info">
              <h3>ERP<span>BUS</span>
              </h3>
              <p>
                ErpBus Tecnologia Ltda <br />
                52.688.684/0001-60<br />
                CRC 028.580/O-8<br />
                CNPC 3118<br />
                APJESP 2210<br />                
                <br />
                <strong>Fone:</strong> (41) 99662-0193<br />
                <strong>Email:</strong> ieder@erpbus.com.br<br />
              </p>
            </div>
        </div>

        <div className="col-lg-2 col-md-6 footer-links">
            <h4>Links Úteis</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="index.html">Home</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#about">Contabilidade</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#services">Serviços</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#planos">Planos</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#contact">Contato</a></li>
            </ul>
        </div>

        <div className="col-lg-3 col-md-6 footer-links">
            <h4>Outros Serviços</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="index.html">Desenvolvimento Site</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="index.html">Desenvolvimento Sistemas Web e Mobile</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="index.html">Certificado Digital</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="index.html">Perícia Contábil</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="index.html">Consultoria Antt</a></li>
            </ul>
        </div>

        <div className="col-lg-3 col-md-6 footer-links">
            <h4>Sistemas</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="www.erpbus.app.br:90">ErpBus - Fretamento</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="www.erpbus.app.br:93">ErpBus - Guia</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="www.erpbus.app.br:92">ErpBus - CTsOs</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="www.erpbus.app.br:91">ErpBus - Excursão</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="www.erpbus.app.br:93">ErpBus - Mobile</a></li>
            </ul>
        </div>

        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        &copy; Copyright <strong><span> ErpBus </span></strong> All Rights Reserved
      </div>
    </div>

  </footer>

  </>
}

export default Footer;