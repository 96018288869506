import NavBar from './navbar';
import Banner from './banner';
import Contabilidade from './contabilidade';
import Servicos from './servicos';
import Helpdesk from './helpdesk';
import Portfolio from './portfolio';
import Plano from './plano';
import Contato from './contato';
import Footer from './footer';

function Site(){
    window.scrollTo(0, 0);    
    return <>

<NavBar></NavBar>
<main id="main" />
<Banner></Banner>
<br />
<Contabilidade></Contabilidade>
<br />
<Servicos></Servicos>
<br />
<Portfolio></Portfolio>
<br />
<Plano></Plano>
<br />
<Helpdesk></Helpdesk>
<br />
<Contato></Contato>
<br />
<br />
<Footer></Footer>

</>

}

export default Site;