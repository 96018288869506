
function Helpdesk(){
    return <>
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">

        <div className="text-center">
          <h3>HelpDesk</h3>
          <p> Caso tenha alguma dúvida, sugestão ou problema pode entrar em contato com nossa equipe, estamos prontos para te atender (41) 99662-0193.</p>
          { /* <a className="cta-btn" href="index.html">Abrir Chamado</a> */ }
        </div>

      </div>
    </section>

    </>
}

export default Helpdesk;