import './contato.css';

function Contato(){
    return <>
    
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Contato</h2>
        </div>

            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>ErpBus Tecnológia Ltda</h4>
                <p>Rua Nilo Peçanha, 754 sala 3 </p>
                <p>São Francisco - Curitiba - Pr</p>
                <p>ieder@erpbus.com.br</p>
                <p><b>(41) 99662-0193</b></p>
              </div>

          </div>


      </div>
    </section>


    </>
}

export default Contato;