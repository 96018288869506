import { Link } from "react-router-dom";
import './plano.css';

function Plano(){
    return <>

<section id="planos" className="services">
  <div className="container" data-aos="fade-up">

    <div className="section-title">
        <h2>Nossos Planos - Valores Mensais</h2>
        <p>Transportadora Turistica</p>
    </div>

    <div className="row">

      <div className="col-lg-4 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
        <Link to={"https://api.whatsapp.com/send?phone=5541996620193&text=Tenho%20interesse%20em%20contratar%20o%20plano%20Enterprise%20"}>
        <div className="icon-box linkcolor">
          <h4>Enterprise</h4>
                <p><i className="ri-check-line"></i> Gestão Contábil e RH</p>
                <p><i className="ri-check-line"></i> Sistema para controlar o Fretamento</p>
                <p><i className="ri-check-line"></i> Sistema para os Guias controlar seus passageiros</p>                    
                <p><i className="ri-check-line"></i> Sistema emissor de CteOs e XML mensal</p>
                <p><i className="ri-check-line"></i> Sistema para controlar as Excursões</p>
                <br />
                <div className="icon"><strong>R$ 300,00</strong></div>
        </div>
        </Link>
      </div>

      <div className="col-lg-4 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
      <Link to={"https://api.whatsapp.com/send?phone=5541996620193&text=Tenho%20interesse%20em%20contratar%20o%20plano%20Pro%20"}>
        <div className="icon-box linkcolor">
          <h4>Pro</h4>
                <p><i className="ri-check-line"></i> Gestão Contábil e RH</p>
                <p><i className="ri-check-line"></i> Sistema para controlar o Fretamento</p>
                <p><i className="ri-check-line"></i> Sistema para os Guias controlar seus passageiros</p>                    
                <p><i className="ri-check-line"></i> Sistema emissor de CteOs e XML mensal</p>
                <br />
                <div className="icon"><strong>R$ 250,00</strong></div>
        </div>
      </Link>  
      </div>

      <div className="col-lg-4 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
      <Link to={"https://api.whatsapp.com/send?phone=5541996620193&text=Tenho%20interesse%20em%20contratar%20o%20plano%20Basic%20"}>
        <div className="icon-box linkcolor">
          <h4>Basic</h4>
                <p><i className="ri-check-line"></i> Gestão Contábil e RH</p>
                <p><i className="ri-check-line"></i> Para qualquer tipo de Empresa, atendemos todos os seguimentos</p>
                <br />
                <div className="icon"><strong>R$ 200,00</strong></div>          
        </div>
      </Link>  
      </div>

    </div>
  </div>
</section>


<section id="planosparceiro" className="services">
  <div className="container" data-aos="fade-up">

    <div className="section-title">
        <h2>Seja nosso parceiro</h2>
        <p>Plano de Indicação</p>
    </div>

    <div className="row">

      <div className="col-lg-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
      <Link to={"https://api.whatsapp.com/send?phone=5541996620193&text=Tenho%20interesse%20em%20contratar%20o%20plano%20Parceria%20"}>
          <div className="icon-box linkcolor">
            <h4>Plano - Parceria</h4>
                  <br />
                  <p> Entre em contato conosco para conhecer nossos planos de parceria ou</p>
                  <p> elaboração de plano especifico para atender a sua demanda.</p>
                  <br />
                  <p>Fone/Whats: <b> (41) 99662-0193 </b></p>
          </div>
      </Link>    
      </div>

      <div className="col-lg-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
      <Link to={"https://api.whatsapp.com/send?phone=5541996620193&text=Tenho%20interesse%20em%20contratar%20o%20plano%20Certificado%20"}>
          <div className="icon-box linkcolor">
            <h4>Certificado Digital</h4>
                  <br />
                  <p> Solicite o seu certificado digital no conforto da sua casa ou empresa, </p>
                  <p> processo 100% online via vídeo chamada.</p>
                  <br />
                  <p>eCNPJ A1<b> R$ 100,00 </b></p>
                  <p>eCPF A1 <b> R$ 100,00 </b></p>
          </div>
      </Link>    
      </div>

    </div>
  </div>
</section>

</>    

}

export default Plano;