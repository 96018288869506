import { BrowserRouter, Route, Routes } from "react-router-dom";
import Site from "./site";
import Plano from "./plano";
import PlanoContratar from "./planocontratar";

function Rotas(){
    return <BrowserRouter>
      <Routes>
        <Route path="/" element={<Site />}/>        
        <Route path="/Plano" element={<Plano />}/>
        <Route path="/PlanoContratar/:id" element={<PlanoContratar />}/>        
      </Routes>
    </BrowserRouter>
}

export default Rotas;