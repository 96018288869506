import { useParams } from "react-router";
import NavBar from "./navbar";

function PlanoContratar(){

    window.scrollTo(0, 0);
   
    const { id } = useParams();

    var idDesc  = '';
    var idValor = '';

    if (id==='1'){
      idDesc = 'Leito';
      idValor= 'R$ 300,00'
    } else if (id==='2'){
      idDesc = 'Executivo';
      idValor= 'R$ 280,00'
    } else if (id==='3'){
      idDesc = 'Convencional';
      idValor= 'R$ 200,00'
    } else if (id==='4'){
      idDesc = 'DD';
      idValor= 'R$ 180,00'
    } else if (id==='5'){
      idDesc = 'LD';
      idValor= 'R$ 80,00'
    } else if (id==='6'){
      idDesc = 'Van';
      idValor= 'R$ 150,00'
    } 
   
   return <>
  
  <NavBar></NavBar>
  <main id="main" />
  <br />

  
<section id="planos" className="services">
  <div className="container" data-aos="fade-up">

    <div className="section-title">
      <h2>Plano</h2>
      <h3>Iniciar processo de contratação do Plano</h3>
      <h5><b>{idDesc}</b> no valor de <b>{idValor}</b> mensais</h5> 
    </div>





  </div>
</section>    

</>

}

export default PlanoContratar;